import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collection-carousel"
export default class extends Controller {
  static targets = ["video"]

  connect() {
    this.videoTargets.forEach(video => {
      video.play()
      video.pause()
    })
  }

  play(event) {
    this.videoTargets.forEach(video => {
      video.play()
      // setTimeout(() => video.classList.add('playing'), 1000)
      video.classList.add('playing')
    })
  }

  pause(event) {
    this.videoTargets.forEach(video => {
      video.pause()
      video.classList.remove('playing')
    })
  }
}