import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="url"
export default class extends Controller {

  static values = {
    parameterName: { type: String, default: '' },
    parameter: { type: String, default: '' },
  }

  connect() {
  }

  modify() {
    const params = new URLSearchParams(location.search);
    params.set(this.parameterNameValue, this.parameterValue);
    window.history.replaceState({}, '', `${location.pathname}?${params}`)
  }
}
