// Allow Animations to be applied to Turbo Frames
// document.addEventListener("turbo:before-stream-render", (event) => {
//   console.log('turbo:before-stream-render')
//   if (event.target.action === "remove") {
//     const targetFrame = document.getElementById(event.target.target)
//     console.log(targetFrame)
//     if (targetFrame.dataset.animateOut) {
//       event.preventDefault()
//       const elementBeingAnimated = targetFrame
//       elementBeingAnimated.classList.add(targetFrame.dataset.animateOut)
//       elementBeingAnimated.addEventListener("animationend", () => {
//       targetFrame.remove()
//     })
//     }
//   }
// })

// set data-animate-in and data-animate-out on elements within the turbo frame
document.addEventListener("turbo:before-frame-render", (event) => {
  console.log('turbo:before-frame-render')
  // Animate In
  const animatedIn = event.detail.newFrame.querySelectorAll("[data-animate-in]")
  console.log(animatedIn)
  animatedIn.forEach(element => {
    element.classList.add(element.dataset.animateIn)
    console.log(element.classList)
  });

  // Animate Out
  const animated = event.target.querySelectorAll("[data-animate-out]")
  if (animated.length > 0) {
    event.preventDefault()

    animated.forEach(element => {
      element.classList.add(element.dataset.animateOut)
      element.addEventListener("animationend", () => {
        event.detail.resume()
      })        
    });
  }
})