import { Controller } from "@hotwired/stimulus"
import { EventBus } from '../../utils/event_bus'

export default class extends Controller {
  static values = {
    loggedIn: { type: Boolean, default: false },
    redirectTo: { type: String, default: "" },
    modal: { type: Boolean, default: false },
    delay: { type: Number, default: 0 }
  }

  connect() {
    if (this.loggedInValue) {
      let url = this.redirectToValue
      
      if (url) {
        if (this.modalValue) {
          setTimeout(() => {
            let frame = document.querySelector('turbo-frame#modal')
            frame.src = url
            frame.reload()
          }, this.delayValue);
        } else {
          setTimeout(() => EventBus.emit('modal--hide'), this.delayValue);
          setTimeout(() => Turbo.visit(url), this.delayValue+250);
        }
      } else {
        setTimeout(() => EventBus.emit('modal--hide'), this.delayValue);
        setTimeout(() => Turbo.visit(window.location.href), this.delayValue+250);        
      }
    }
  }

}
