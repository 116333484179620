import { Controller } from '@hotwired/stimulus'
import { EventBus } from '../../utils/event_bus'

export default class extends Controller {
  static targets = [ "header", "cart" ]

  initialize() {
  }  

  connect() {
    this.handleScroll = this.handleScroll.bind(this)
    this.cartToggle = this.cartToggle.bind(this);

    window.addEventListener("scroll", this.handleScroll)
    EventBus.on('basket--toggle', this.cartToggle);
    this.handleScroll()
  }

  disconnect() {
    EventBus.off('basket--togle', this.cartToggle);
    window.removeEventListener("scroll", this.handleScroll)
  }

  cartToggle() {
    this.cartTargets.forEach(cart => {
      cart.classList.toggle("active")
    });      
  }

  handleScroll() {
    let opacity = Math.max(document.documentElement.scrollTop/100, 0);
    this.headerTargets.forEach(header => {
      header.style.background = `rgba(0, 0, 0, ${opacity})`;
    });  
  }

  basketToggle() {
    EventBus.emit(`basket--toggle`);
  }

}
