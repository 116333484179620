import { Controller } from "@hotwired/stimulus"
import { EventBus } from '../../utils/event_bus'

export default class extends Controller {
  static targets = [ "elements", "element", "rightArrow", "leftArrow" ]
  static values = {
    currentIndex: { type: Number, default: 0 }
  }

  connect() {
    this.animate()
    this.currentIndexValue = 0
    this.animate = this.animate.bind(this)
    EventBus.on('carousel--animate', this.animate);
  }

  disconnect() {
    EventBus.off('carousel--animate', this.animate);
  }

  animate () {
    const base = 200
    let delay = 0

    this.elementTargets.forEach((element, i) => {
      element.classList.remove('hidden')
    })
    this.elementTargets.forEach((element, i) => {
      if (element.classList.contains('faded')) {
        element.style.setProperty('--animate-delay', (5 * base) + 'ms')
      } else {
        element.style.setProperty('--animate-delay', (delay * base) + 'ms')
        delay++
      }
      element.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s')
    })
  }

  onScreenCount () {
    if (window.innerWidth > 768) {
      return 4
    }
    return 2
  }

  right(event) {
    event.preventDefault()
    if (this.currentIndexValue < this.elementTargets.length - 1) { 
      this.currentIndexValue = Math.min(this.currentIndexValue + this.onScreenCount(), this.elementTargets.length - this.onScreenCount() ) 
    }
  }

  left(event) {
    event.preventDefault()

    if (this.currentIndexValue > 0) { 
      this.currentIndexValue = Math.max(this.currentIndexValue - this.onScreenCount(), 0) 
    }
  }

  currentIndexValueChanged() {
    this.updateElements()
    this.updateArrows()
  }

  updateElements() {
    if (this.currentIndexValue > 0) {
      this.elementsTarget.style.marginLeft = `calc((var(--vw-padding) + (var(--vw-carousel-element) + var(--vw-carousel-element-gap)) * ${this.currentIndexValue}) * -1)`
    } else {
      this.elementsTarget.style.marginLeft =  'calc(var(--vw-padding) * -1)'
    }

    this.elementTargets.forEach(element => {
      element.classList.toggle('onscreen', element.dataset.index >= this.currentIndexValue - 1 && element.dataset.index < this.currentIndexValue + (this.onScreenCount() - 1))
      element.classList.toggle('faded', this.currentIndexValue > element.dataset.index || element.dataset.index > this.currentIndexValue + this.onScreenCount() - 1)
    })
  }

  updateArrows() {
    this.rightArrowTarget.classList.toggle('hidden', this.currentIndexValue >= this.elementTargets.length - this.onScreenCount())
    this.leftArrowTarget.classList.toggle('hidden', this.currentIndexValue == 0)
  }

}
