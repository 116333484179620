import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="credits"
export default class extends Controller {
  static targets = ["option", "credits", "submit"]

  select(event) {
    this.creditsTarget.value = event.params.amount;
    this.optionTargets.forEach(option => option.classList.remove("selected"))
    event.target.classList.add("selected")
    this.submitTarget.disabled = false
  }

  custom(event) {
    this.submitTarget.disabled = event.target.value.length === 0
  }

}
