import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "togglable" ]
  static classes = [ "active" ]
  static values = {
    active: { type: Boolean, default: false }
  }

  connect () {
  }

  toggle(event) {
    event.preventDefault();
    this.activeValue = !this.activeValue;
  }

  clickOutside (event) {
    if (this.element === event.target || this.element.contains(event.target)) return;
    this.activeValue = false;
  }

  activeValueChanged() {
    this.togglableTargets.forEach(togglable => {
      togglable.classList.toggle("active", this.activeValue)
    })
  }

}
