import { Controller } from "@hotwired/stimulus"
import { EventBus } from '../../utils/event_bus'

export default class extends Controller {
  static values = {
    delay: {type: Number, default: 0}
  }
  
  connect() {
    setTimeout(() => EventBus.emit('modal--hide'), this.delayValue);
  }
}