import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "left", "right", "thumb" ]

  static values = {
    active: { type: String, default: "" }
  }

  toggle(event) {
    if (this.leftTarget.dataset.value.toString() == this.activeValue.toString()) {
      this.setValue(this.rightTarget.dataset.value)
    } else {
      this.setValue(this.leftTarget.dataset.value)
    }
  }

  left() {
    this.setValue(this.leftTarget.dataset.value)
  }

  right() {
    this.setValue(this.rightTarget.dataset.value)
  }

  setValue(value) {
    this.activeValue = value
    if (this.leftTarget) {
      this.leftTarget.classList.toggle("active", this.leftTarget.dataset.value.toString() == this.activeValue.toString())
    }
    if (this.thumbTarget) {
      this.thumbTarget.classList.toggle("active", this.leftTarget.dataset.value.toString() == this.activeValue.toString())
    }
    if (this.rightTarget) {
      this.rightTarget.classList.toggle("active", this.rightTarget.dataset.value.toString() == this.activeValue.toString())
    }
    let input = this.element.querySelector("input")
    input.value = this.activeValue;
    setTimeout(() => input.dispatchEvent(new Event('input')), 250)
  }

}
