import { Controller } from '@hotwired/stimulus'
import { EventBus } from '../../utils/event_bus'

export default class extends Controller {
  static targets = [ "series" ]
  static values = {
    active: { type: Number, default: 0}
  }

  connect () {
  }

  select(event) {
    this.activeValue = event.target.dataset.index;
  }

  activeValueChanged() {
    EventBus.emit("carousel--animate")
    this.seriesTargets.forEach(series => {
      series.classList.toggle("active", series.dataset.index == this.activeValue)
      // series.classList.remove(["animate__animated", "animate__fadeInLeft", "animate__delay-1s"])
      // series.classList.add(["animate__animated", "animate__fadeInLeft", "animate__delay-1s"])

      if (series.dataset.index == this.activeValue) {
        let content = series.querySelector(".series-content")
        
        if ( content) { 
          content.style.display = "none";
          setTimeout(() => content.style.display = "block", 1);
        }
  
        let turboframe = series.querySelector("turbo-frame")
        if (turboframe) {
          turboframe.innerHTML = "";
          turboframe.reload()
        }
      }
    })
  }

}
