import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slide-toggle"
export default class extends Controller {
  static targets = [ "toggleable", "toggleStatus", "label" ]
  
  static values = {
    labelClose: { type: String, default: "Less Info" },
    labelOpen: { type: String, default: "More Info" }
  }

  initialize() {
    this.open = false
    this.setStyle = this.setStyle.bind(this);
  }

  connect() {
    window.addEventListener('resize', this.setStyle) 
    this.setStyle()
  }

  disconnect() {
    window.removeEventListener('resize', this.setStyle) 
  }

  toggle (event) {
    event.stopPropagation()
    this.open = !this.open
    this.labelTargets.forEach(t => t.innerHTML = this.open ? this.labelCloseValue : this.labelOpenValue)
    this.toggleStatusTargets.forEach(t => t.classList.toggle("open", this.open))
    this.setStyle()
  }

  setStyle () {
    this.toggleableTargets.forEach(t => {
      const heightSize = this.open ? t.scrollHeight : 0
    
      t.style.overflow = 'hidden';
      t.style.transitionProperty = 'height';
      t.style.height = `${heightSize}px`;
      t.style.transitionDuration = '300ms';
    })
  }


}
