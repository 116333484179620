import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "features", "feature", "video", "dot", "rightArrow", "leftArrow" ]
  static values = {
    currentFeature: { type: Number, default: 0 }
  }

  connect() {
    this.animate()
    this.currentFeatureValue = 0
    this.showVideo = this.showVideo.bind(this)
    this.videoTargets.forEach(video => {
      video.addEventListener('loadeddata', this.showVideo(video))
    })
  }

  showVideo(video) {
    setTimeout(() => video.classList.add('active'), 1000)
  }

  animate () {
    const base = 200
    let delay = 0

    this.featureTargets.forEach((element, i) => {
      element.classList.remove('hidden')
    })
    this.featureTargets.forEach((element, i) => {
      if (element.classList.contains('faded')) {
        element.style.setProperty('--animate-delay', (5 * base) + 'ms')
      } else {
        element.style.setProperty('--animate-delay', (delay * base) + 'ms')
        delay++
      }
      element.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s')
    })
  }

  select(event) {
    event.preventDefault()
    this.currentFeatureValue = event.currentTarget.dataset.index;
  }

  right(event) {
    event.preventDefault()
    this.currentFeatureValue = Math.min(this.currentFeatureValue + 1, this.featureTargets.length - 1)
  }

  left(event) {
    event.preventDefault()
    this.currentFeatureValue = Math.max(this.currentFeatureValue - 1, 0)
  }

  currentFeatureValueChanged() {
    this.updateFeatures()
    this.updateVideos()
    this.updateDots()
    this.updateArrows()
  }

  updateFeatures() {
    if (this.currentFeatureValue > 0) {
      this.featuresTarget.style.marginLeft = `calc((var(--vw-padding) + (var(--vw-feature) + var(--vw-feature-gap)) * ${this.currentFeatureValue}) * -1)`
    } else {
      this.featuresTarget.style.marginLeft =  'calc(var(--vw-padding) * -1)'
    }

    this.featureTargets.forEach(element => {
      element.classList.toggle('onscreen', element.dataset.index == this.currentFeatureValue)
      element.classList.toggle('faded', element.dataset.index != this.currentFeatureValue)
    })

  }

  updateVideos() {
    this.videoTargets.forEach(element => {
      if (element.dataset.index == this.currentFeatureValue || window.innerWidth < 768) {
        element.play()
      } else {
        element.pause()
      }
    })
  }

  updateDots() {
    this.dotTargets.forEach(element => {
      element.classList.toggle('active', element.dataset.index == this.currentFeatureValue)
    })
  }

  updateArrows() {
    this.rightArrowTarget.classList.toggle('hidden', this.currentFeatureValue == this.featureTargets.length - 1)
    this.leftArrowTarget.classList.toggle('hidden', this.currentFeatureValue == 0)
  }

}
