import { Controller } from "@hotwired/stimulus"
import TomSelect      from "tom-select"

export default class extends Controller {
  connect() {
    this.ts = new TomSelect(this.element, {
      // plugins: this.element.attributes.multiple ? ['remove_button'] : [],
      plugins: ['remove_button'],
    })
    this.ts.on('focus', () => {
      // console.log('scroll into view')
      // window.scroll({top: this.element.offsetTop - 50, behavior: 'smooth'})
      // let isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));  
      // if (isTouchDevice) {
      //   this.element.scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"})
      // }
    })
  }
}

